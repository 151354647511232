import React from "react";
import "react-lazy-load-image-component/src/effects/blur.css";
// components
import Layout from "../components/layout";
import SEO from "../components/seo";
import FlexCard from "../components/flexcard";
import DrivingSchoolCard from "../components/drivingschoolcard";
import HomeVideoBackground from "../components/homevideobackground";
import Track from "../components/track";
import settings from "../../settings";

// images
const poster = `${settings.IMAGES_BASE_URL}/images/home/poster_home.jpg`;
const teenschool = `${settings.IMAGES_BASE_URL}/images/home/bmw-teen-school.jpg`;
const driverschool = `${settings.IMAGES_BASE_URL}/images/home/bmw_driver_school.jpg`;
const mschool = `${settings.IMAGES_BASE_URL}/images/home/bmw_m_school.jpg`;
const mSummerBanner = `${settings.IMAGES_BASE_URL}/images/home/m-summer-competition.jpg`;
const indyTeaser = `${settings.IMAGES_BASE_URL}/images/home/indy-teaser.jpg`;
const oktoberfestBanner = `${settings.IMAGES_BASE_URL}/images/home/oktoberfest.jpg`;
const homeVideo = `${settings.IMAGES_BASE_URL}/videos/home-video-opening-2022.mp4`;
const pgaWestBanner = `${settings.IMAGES_BASE_URL}/images/home/pga-west-home-banner.jpg`;


const today = new Date();
const oktoberfest = today > new Date(2024,8,6) && today <= new Date("2024-10-27 17:00:00 PDT");

const IndexPage = () =>  {

  const getFirstCard = () => { 
    if (oktoberfest) {
      return (
        <>
          <FlexCard
              rowType="row"
              image={oktoberfestBanner}
              imageAlt="OKTOBERFEST HEADS WEST"
              header="OKTOBERFEST HEADS WEST"
              subheader="JOIN US FOR A SPECIAL EVENT"
              text="Oktoberfest returns to the west coast on October 27, with driving events, delicious food, and more."
              btnText="Learn More"
              btnStyle="btn-blue"
              route="/experiences"
          />
        </>
      )
    } else {
      return (
        <>
          <FlexCard
              rowType="row"
              image={mSummerBanner}
              imageAlt="BEST FRENEMIES"
              header="BEST FRENEMIES"
              subheader="M SUMMER COMPETITION IS HERE"
              text="Our newest Experience invites you to hop into some M cars and take on the competition with timed laps and drag racing."
              btnText="Learn More"
              btnStyle="btn-blue"
              route="/experiences"
          />
        </>
      )
    }
  }
  
  return (
      <Layout>
        <SEO title="BMW Performance Driving School" description="Learn total car control behind the wheel of a BMW at the BMW Performance Driving School. Multiple class offerings and challenges await in one of our three locations." />
        <div className="homepage">
          <HomeVideoBackground
            url={homeVideo}
            poster={poster}
            hideMobileHeader={true}
            topLeftChildren={
              <div className="text_left">
                <div>DRIVE ON THE EDGE OF PHYSICS.</div>
              </div>
            }
          />
          <section className="performance-center">
            <div className="container">
              <div className="drivingschool__header">
                <h1>THE BMW PERFORMANCE DRIVING SCHOOL</h1>
                <p>
                  An open track, the Ultimate Driving Machine, and you in the driver’s seat. <br/>
                  Prepare for a BMW driving experience like no other.
                </p>
              </div>
            </div>
            {getFirstCard()}
            <FlexCard
            rowType="row-reverse"
            image={indyTeaser}
            imageAlt="ROARING OUT OF THE INDY GARAGE"
            header="ROARING OUT OF THE INDY GARAGE"
            subheader="FALL 2025 DATES BOOKING SOON"
            text="Prepare for more thrills at the Indianapolis Motor Speedway this coming fall. Expect M car excitement around one of the country’s most famous tracks."
          />
          <FlexCard
            rowType="row"
            image={pgaWestBanner}
            imageAlt="SPEED TIME GOES WITH TEE TIME."
            header="SPEED TIME GOES WITH TEE TIME."
            subheader="PGA WEST DISCOUNT"
            text="Book a round of golf at the exclusive PGA WEST for a discounted rate when you book a class at our Thermal location. Be on the lookout for a link in your confirmation email for more details."
          />
          {/* <FlexCard
            rowType="row"
            image={bimmerbeliever}
            imageAlt="BIMMER BELIEVER"
            header="BIMMER BELIEVER"
            subheader="BMW ACCESSORIES AND LIFESTYLE"
            text="Whether it’s an M pen or M Power, you’ll find excitement waiting to be added with Genuine BMW Accessories at ShopBMWUSA.com."
            btnText="Shop now"
            btnStyle="btn-blue"
            externalURL="http://www.shopbmwusa.com?utm_source=bmwperformancecentercom&utm_medium=FMAbanner&utm_campaign=AFTERfebmar"
          /> */}
          </section>
          <Track variant="white" />
          <section className="drivingschool">
            <div className="container">
              <div className="drivingschool__header">
                <h2>BMW DRIVING SCHOOLS</h2>
              </div>
            </div>
            <DrivingSchoolCard
              rowType="row"
              image={teenschool}
              imageAlt="BMW Teen School 01"
              header="BMW TEEN SCHOOL"
              subheader="UNDERSTANDING THE BASICS"
              text="Leave your perception of driver’s education class at the door: this isn’t about parallel parking. Teens will learn total car control at the limit on our closed track, making them much more alert and aware. LOLs are encouraged."
              learnUrl="/teenschool"
              learnBtnStyle="btn-bordered"
              bookBtnStyle="btn-blue"
              bookUrl="https://pds.eventsbmw.com/?brand=BMW&class=BMW_One_Day_Teen_School"
            />
            <DrivingSchoolCard
              rowType="row"
              image={driverschool}
              imageAlt="BMW Driver's School"
              header="BMW DRIVER’S SCHOOL"
              subheader="A THRILLING BMW DRIVING EXPERIENCE"
              text="Let’s bring you up to speed on the best ways to handle a BMW’s advanced capabilities with classes that focus on car control, proper vision, brake points and more. If your heart rate just went up, this is the class for you."
              learnUrl="/driverschool"
              learnBtnStyle="btn-bordered"
              bookBtnStyle="btn-blue"
              bookUrl="https://pds.eventsbmw.com/?brand=BMW&class=BMW_One_Day_School"
            />
            <DrivingSchoolCard
              rowType="row"
              image={mschool}
              imageAlt="BMW M School"
              header="BMW M SCHOOL"
              subheader="IT’S PRACTICALLY A BMW RACE SCHOOL"
              text="Unleash the full potential of our M vehicles as you push them through high-speed stability, control and drifting exercises. Prepare for the ultimate drive."
              learnUrl="/mschool"
              learnBtnStyle="btn-bordered"
              bookBtnStyle="btn-blue"
              bookUrl="https://pds.eventsbmw.com/?brand=M&class=One_Day_M_School"
            />
          </section>
        </div>
      </Layout>
    );
}

export default IndexPage;